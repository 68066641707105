import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";

import { sbaserver } from '../helpers/SBAServer'

import DashboardByContent from './DashboardByContent'

// reactstrap components
import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Label,
  FormGroup,
  Input,
  Table,
  Row,
  Col,
  UncontrolledTooltip
} from "reactstrap";

// core components
/*
import {
  chartExample1,
  chartExample2,
  chartExample3,
  chartExample4
} from "variables/charts.jsx";
*/

class Dashboard extends React.Component {

  constructor(props) {
    super(props);
    this.timeDataBlank = { url: "", elapsed: 0, remaining: 0, duration: 0, live: false, size: { height: 0, width: 0 } }
      this.state = { deviceList: {}, contentDeviceList: {}, contentList: {} }

  }


    componentDidMount() {
        sbaserver.on('time', this.handleTimeChange.bind(this)) // broken
        sbaserver.on('status', this.handleStatusChange.bind(this)) // broken
        sbaserver.on('activeDevices', this.handleDeviceListChange.bind(this))
        sbaserver.on('content', this.handleContentListChange.bind(this))
        var intervalDevID = setInterval(this.queryDevices.bind(this), 10000)
        this.setState({ intervalDevID })
        var intervalContID = setInterval(sbaserver.emit('queryContent'), 60000)
        this.setState({ intervalContID })
        var intervalCleanContID = setInterval(this.checkContentDeviceList.bind(this), 10000)
        this.setState({intervalCleanContID})
    }


    componentWillUnmount() {
        sbaserver.removeListener('activeDevices',this.handleDeviceListChange)
        sbaserver.removeListener('time',this.handleTimeChange)
        sbaserver.removeListener('status',this.handleStatusChange)
        sbaserver.removeListener('content',this.handleContentListChange)
        clearInterval(this.state.intervalContID)
        clearInterval(this.state.intervalDevID)
        clearInterval(this.state.intervalCleanContID)
    }

    queryDevices() {
      console.log("Querying Device List")
      sbaserver.emit('queryDevices');
    }

    handleDeviceListChange(deviceData) {
      console.log('Got device list')
      
        let deviceList = {}
        for (let socketID in deviceData) {
            deviceList[socketID] = deviceData[socketID]
        }
        this.setState({ deviceList })
        this.assignContentDevices()
    }

    handleContentListChange(contentData) {
        let contentList = {}
        for (let content in contentData) {
            //contentList[contentData[content].url] = contentData[content] // sort by URL
            contentList[contentData[content].path] = contentData[content] // sort by Room
        } 
        this.setState({ contentList })
    }


    handleTimeChange(inData) {
      //console.log("time data: ",inData)
//      let deviceList = this.state.deviceList;
//	let device = deviceList[inData.socketID] || { socketID: inData.socketID, uuid: inData.uuid, name: "Unknown" }
	this.assignContentDevices(inData)
    }


    handleStatusChange(inData) {
      //console.log("Status data: ",inData)
//	let deviceList = this.state.deviceList;
	//	let device = deviceList[inData.socketID] || { socketID: inData.socketID, uuid: inData.uuid, name: "Unknown" }
	this.assignContentDevices(inData)	
    } 



    //assignDevices(device, data) {
    assignContentDevices(inData) {
      //console.log("Assign Device: ", device, data)
      let contentDeviceList = Object.assign({}, this.state.contentDeviceList);
      const deviceList = this.state.deviceList
      for (let iter in deviceList) {
        let device = deviceList[iter]
        //let uuid = device.uuid
        let socketID = device.socketID
        //let room = device.path
        let path = device.path // data.url

      // if the contentDeviceList doesn't exist, create it
      	//if (path && !!device.statusData && !!device.statusData.url && device.statusData.url != 'na') {
        if (path) {
    	    if (!contentDeviceList) { contentDeviceList = {} }
          if (!contentDeviceList[path]) { contentDeviceList[path] = {} }
            contentDeviceList[path][socketID] = Object.assign({}, contentDeviceList[path][socketID], device)
	    if (!!inData && inData.socketID == socketID) {
		contentDeviceList[path][socketID].statusData = Object.assign({}, contentDeviceList[path][socketID].statusData, inData)
	    }
          contentDeviceList[path][socketID].counter = 2; 
        //
          
        } else { // time update
          for (let url2 in contentDeviceList) {
            if (!!contentDeviceList[url2][socketID]) {
	    if (!!inData && inData.socketID == socketID) {
		contentDeviceList[path][socketID].statusData = Object.assign({}, contentDeviceList[path][socketID].statusData, inData)
	    }
              contentDeviceList[url2][socketID].counter = 2; 
            //break;
            }
          }
        }
        this.setState({contentDeviceList})

      }
    }

    checkContentDeviceList() {
      //console.log("cleaning content list:", )
      let contentDeviceList = Object.assign({}, this.state.contentDeviceList)
      // iterate over all entries
      for (let url in contentDeviceList) {
        for (let socketID in contentDeviceList[url]) {
            // decrement their counter once per second
            contentDeviceList[url][socketID].counter--;
            if (contentDeviceList[url][socketID].counter < 1) {
              // prune them if they haven't given us an update recently
                delete contentDeviceList[url][socketID]
                if (Object.keys(contentDeviceList[url]).length === 0) {
                  delete contentDeviceList[url]
                }
            }
        }
      }
    	this.setState({contentDeviceList})
    }
    

  systemQueryLive() {
    sbaserver.emit('systemQueryLive', {})
  }

//                         <th>Device/System</th>

    

  render() {

    //console.log("ContentDeviceList: ", this.state.contentDeviceList)
    let totalDeviceCount = 0
    let totalAttendeeCount = 0
    const contentDeviceList= this.state.contentDeviceList
    for (let path in contentDeviceList) {
      for (let device in contentDeviceList[path]) {
        totalDeviceCount++
        const count = parseInt(contentDeviceList[path][device].attendance)
        if ( count > 0) { totalAttendeeCount += count } else { totalAttendeeCount += 1 }
      }
    }


    return (
      <>
        <div className="content">
          <Row>

            <Col lg="12" md="12">
              <Card>
                <CardHeader>
                  <CardTitle tag="h2">
                    Viewership { totalDeviceCount ? ": " + totalDeviceCount + " Devices / " + totalAttendeeCount + " Viewers" : "" }                  
                  </CardTitle>
                  <Button onClick={this.systemQueryLive.bind(this)}>Force reload default URL</Button>
                </CardHeader>
                <CardBody>
                  <Table className="tablesorter" responsive>
                    <thead className="text-primary">
                      <tr>
			<th></th>
                        <th>Name</th>
                        <th>Resolution</th>
      			<th className="d-none d-md-table-cell">Bit Rate (bps)</th>
			<th className="d-none d-md-table-cell">Bandwidth</th>
			<th>Status</th>
			<th className="d-none d-md-table-cell">Duration</th>
			<th>Position</th>
                      </tr>
                    </thead>
                    <tbody>


		      
		    { Object.keys(this.state.contentDeviceList).map((path) => {
          if (this.state.contentList[path])  {
			return ( <DashboardByContent url={!!this.state.contentList[path].url ? this.state.contentList[path].url : "na"} path={path} key={path}
					    contentList={this.state.contentList[path]}
					    contentDeviceList={this.state.contentDeviceList[path]}
				    /> )
          }
		    })
		    }


                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default Dashboard;


Date.prototype.timeNow = function(){
    return ((this.getHours() === 0 ? 12 : ((this.getHours()>12) ? (this.getHours()-12) : this.getHours()))
       +":"
	    + ((this.getMinutes() < 10)?"0":"") + this.getMinutes()
	    +":"
     
	    + ((this.getSeconds() < 10)?"0":"") + this.getSeconds()  
       +" "
	    + ((this.getHours()>11) ? ('PM'):'AM')
	    );
};

Date.prototype.timeLittle = function(){
    return ((this.getHours() === 0 ? 12 : ((this.getHours()>12) ? (this.getHours()-12) : this.getHours()))
       +":"
	    + ((this.getMinutes() < 10)?"0":"") + this.getMinutes()
	    //+":"
     
	    //+ ((this.getSeconds() < 10)?"0":"") + this.getSeconds()   
     
       +" "
	    + ((this.getHours()>11) ? ('p.m.'):'a.m.')
	    );
};

Date.prototype.timeNoLetters = function(){
    return ((this.getHours() === 0 ? 12 : ((this.getHours()>12) ? (this.getHours()-12) : this.getHours()))
       +":"
	    + ((this.getMinutes() < 10)?"0":"") + this.getMinutes()
	    //+":"
     
	    //+":"
     
	    //+ ((this.getSeconds() < 10)?"0":"") + this.getSeconds()   
     
	    );
};
