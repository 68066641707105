import React from "react";
import classNames from "classnames";
import { Button } from 'reactstrap'
import DashboardItem from './DashboardItem'

import { sbaserver } from '../helpers/SBAServer'


export default class DashboardByContent extends React.Component {

    assignContentToAll(url) {
        sbaserver.emit('changeURLAll',{ URL: url })
    }

	constructor(props) {
		super(props)
		this.state = { hidden: true }
	}
	
	changeHidden() {
		this.setState({hidden: !this.state.hidden})
	}

    render() {

		let url = this.props.url
		let path = this.props.path
		let contentList = this.props.contentList // now just the one
		let contentDeviceList = this.props.contentDeviceList // now just the devices watching this URL
		let attendeeCount = 0;

		for (let device in contentDeviceList) {
			let count = parseInt(contentDeviceList[device].attendance)
			if (count > 0) { attendeeCount += count} else { attendeeCount += 1 }
		}

	//	console.log("Devicelist: ", contentDeviceList)
	//	console.log("Content List:", contentList)
	




/*
 { url !== "na" ?
	      <>
	      <div style={{flexGrow: 2, textAlign: "right"}} className="d-none d-md-block"><small> <a href={url}>{url}</a></small></div>
	      <div style={{flexGrow: 1, textAlign: "right"}}>

		</div>
		</>
		 : "" }
		 */


	return(<>
	    <tr><td colSpan={8}>
	    <div  style={{display: "flex", flexFlow: "row wrap", alignItems: "center", alignContent: "stretch"}} >
	       <div style={{flexGrow: 3}}><span style={{marginBottom: 0}} onClick={this.changeHidden.bind(this)}> {!!contentList.name ? contentList.name : "" }</span> </div>
		   <div style={{flexGrow: 1, textAlign: "right", verticalAlign: "middle"}}><span style={{marginBottom: 0}}>{Object.keys(contentDeviceList).length} Devices / {attendeeCount} Viewers</span></div>
	   
	    </div>

	    </td></tr>

		{ this.state.hidden ? <></> : 
		Object.keys(contentDeviceList).map( (socketID) => {
				let device = contentDeviceList[socketID]
				return (<DashboardItem key={socketID} device={device} />)
	    	}) 
	    }
	    
	    </>)
	

    }

}
