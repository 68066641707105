import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";

import { sbaserver } from '../helpers/SBAServer'


// reactstrap components
import {
    Alert,
    Button,
    ButtonGroup,
    ButtonDropdown,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
    Label,
  FormGroup,
  Input,
    Table,
    Toast,
    ToastHeader,
    ToastBody,
  Row,
  Col,
  UncontrolledTooltip
} from "reactstrap";

// core components
/*
import {
  chartExample1,
  chartExample2,
  chartExample3,
  chartExample4
} from "variables/charts.jsx";
*/

class Content extends React.Component {

  constructor(props) {
    super(props);
      this.state = { contentList: {}, deviceList: {},  buttons: { defaults: {}, devices: {} }, selectedContent: {}, selectedDevice: {} }
  }


    componentDidMount() {
        sbaserver.on('activeDevices', this.handleDeviceListChange.bind(this))
        sbaserver.on('content', this.handleContentListChange.bind(this))
        //sbaserver.on('activeDevices', this.handleActiveDeviceListChange.bind(this))
        var intervalDevID = setInterval(sbaserver.emit('queryDevices'), 3000)
        this.setState({ intervalDevID })
        var intervalContID = setInterval(sbaserver.emit('queryContent'), 10000)
        this.setState({ intervalContID })
	    }

    componentWillUnmount() {
        sbaserver.removeListener('activeDevices',this.handleDeviceListChange)
        sbaserver.removeListener('content',this.handleContentListChange)
        //sbaserver.removeListener('activeDevices',this.handleActiveDeviceListChange);
        clearInterval(this.state.intervalContID)
        clearInterval(this.state.intervalDevID)
    }


    toggle(group,key){
    	let truth = this.state.buttons[group][key] || false
//	console.log("Got button change request for group " + group + " and key " + key + " truth:",truth);
	    let mybuttons = {}
    	Object.assign(mybuttons, this.state.buttons)
	    Object.assign(mybuttons, { [group] : { [key]: !truth }}) 
	    this.setState({buttons: mybuttons} )
    }
    

    handleContentListChange(contentData) {
        let contentList = {}
        for (var content in contentData) {
            contentList[contentData[content].path] = contentData[content]
        } 
        this.setState({ contentList })
    }

    handleDeviceListChange(deviceData) {
      let deviceList = {}
      for (var device in deviceData) {
          deviceList[deviceData[device].socketID] = deviceData[device]
      }
      //console.log("deviceList: ", deviceList)
      this.setState({ deviceList })
  }

//                         <th>Device/System</th>


    assignContent(content,device) {
        //console.log("assigning content to device: ", device)
        if (!!device.socketID) {
            sbaserver.emit('changeURL', { URL: content.url, socketID: device.socketID })
        }
    }

    
    assignDefault(content) {
        sbaserver.emit('setDefaultURL', content)
    }
    disableDefault() {
        sbaserver.emit('unsetDefaultURL', {})
    }

    assignContentToRoom(content,room) {
        sbaserver.emit('changeURLAll',{ URL: content.url, room: room })
    }

    assignContentToAll(content,pathlist) {
      for (var room in pathlist) {
        sbaserver.emit('changeURLAll',{ URL: content.url, room: pathlist[room] })
      }
      
  }

    
  render() {

      let contentList = this.state.contentList

      let deviceList = this.state.deviceList

      let seen = {}
      const pathList = Object.keys(deviceList).map((socketID) => {
        const device = deviceList[socketID]
        if (!seen[device.path]) {
          seen[device.path] = true
          return device.path
        }
      })
      
      return (
      <>
        <div className="content">
          <Row>

            <Col lg="12" md="12">
              <Card>
                <CardBody>
		  {  Object.keys(contentList).map((key) => {
		      let item = contentList[key]

		      const devDisplay = Object.keys(deviceList).map((key) => {
			      let device = deviceList[key]
    			  return <DropdownItem onClick={this.assignContent.bind(this, item, device)}>{device.name}</DropdownItem>
		      })

          const pathDisplay = pathList.map((path) => { 
            if (!!path && path.match(/\w+/)) {
              return <DropdownItem onClick={this.assignContentToRoom.bind(this,item,path)}>Viewers at {path}</DropdownItem>
            }
          })
		      
		      const buttonGroup = <>

			  <UncontrolledDropdown group>
				<DropdownToggle caret size="sm" color="info" data-toggle="devices" ><i  className="tim-icons icon-triangle-right-17" /></DropdownToggle>
						  <DropdownMenu>
							{pathDisplay}
              <DropdownItem divider />
              <DropdownItem onClick={this.assignContentToAll.bind(this,item,pathList)}>All Viewers (Don't Use)</DropdownItem>
							<DropdownItem divider />
							{devDisplay}
						</DropdownMenu>
				    </UncontrolledDropdown>	  										
			<UncontrolledDropdown group>
			      <DropdownToggle caret size="sm" color="success" data-toggle="defaults"><i className="tim-icons icon-settings-gear-63" /></DropdownToggle>
					      <DropdownMenu>
						    <DropdownItem onClick={this.assignDefault.bind(this,item)}>Set as Default</DropdownItem>
			                            <DropdownItem onClick={this.disableDefault.bind(this,item)}>Remove Default</DropdownItem>
						  </DropdownMenu>
				    </UncontrolledDropdown>	  

		      </>
		      

		      return <Row style={{paddingTop: "10px"}}><Col>
			  <Row><Col xs="12" md="6" ><h4>{item.name}</h4><p>{item.description}</p></Col>
				<Col xs="12" md="6" style={{textAlign: "right"}}>{buttonGroup}</Col></Row>
			  </Col></Row>
		      })
		  }
		</CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default Content;

