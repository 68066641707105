import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";

import { sbaserver } from '../helpers/SBAServer'


// reactstrap components
import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Label,
  FormGroup,
  Input,
  Table,
  Row,
  Col,
  UncontrolledTooltip
} from "reactstrap";

// core components
/*
import {
  chartExample1,
  chartExample2,
  chartExample3,
  chartExample4
} from "variables/charts.jsx";
*/

class Devices extends React.Component {

  constructor(props) {
    super(props);

  }


    componentWillMount() {
        sbaserver.on('devices', this.handleDeviceListChange.bind(this))
        sbaserver.on('content', this.handleDevicesListChange.bind(this))
        sbaserver.emit('queryDevices')
        sbaserver.emit('queryDevices')
	    }


    componentWillUnmount() {
        sbaserver.removeListener('devices',this.handleDeviceListChange)
        sbaserver.removeListener('content',this.handleDevicesListChange)
    }

    handleDeviceListChange(deviceData) {
        let deviceList = {}
        for (var device in deviceData) {
            deviceList[deviceData[device].uuid] = deviceData[device]
        }
        this.setState({ deviceList })
    }


    handleDevicesListChange(contentData) {
        let contentList = {}
        for (var content in contentData) {
            contentList[contentData[content].url] = contentData[content]
        } 
        this.setState({ contentList })
    }


    handleTimeChange(inData) {

        let deviceList = this.state.deviceList;
        let device = deviceList[inData.uuid] || { uuid: inData.uuid, name: "Unknown" }

	this.assignDevice(device,inData)

	//console.log("Got timedata: ", inData)
    }


    assignDevice(device, data) {
	let contentDeviceList = Object.assign({}, this.state.contentDeviceList);
	let uuid = device.uuid
	let url = data.url


	for (var url2 in contentDeviceList) {
	    if (!url) {
		if (!!contentDeviceList[url2][uuid]) {
		    contentDeviceList[url2][uuid].statusData = Object.assign({}, contentDeviceList[url2][uuid].statusData, data)
		    break
		}
	    } else {
		if (url === url2) { continue }
	// prune the device from wherever else it may be
		if (!!contentDeviceList[url2]) {
		    if (!!contentDeviceList[url2][uuid]) {
			delete contentDeviceList[url2][uuid]
			if (Object.keys(contentDeviceList[url2]).length === 0) {
       			    delete contentDeviceList[url2]
			}
		    }
		}
	    }
	}

	if (url) {
	    if (!contentDeviceList) { contentDeviceList = {} }
	    if (!contentDeviceList[url]) { contentDeviceList[url] = {} }

	// assign it to the new list, even if it already exists
	    contentDeviceList[url][uuid] = Object.assign({}, contentDeviceList[url][uuid], device)
	    contentDeviceList[url][uuid].statusData = Object.assign({}, contentDeviceList[url][uuid].statusData, data)
	}
	this.setState({contentDeviceList})
    }
    

    handleStatusChange(inData) {
        let deviceList = this.state.deviceList;
        let device = deviceList[inData.uuid] || { uuid: inData.uuid, name: "Unknown" }
//        let myData =  Object.assign({}, inData, {name:  device.name, device: device})

	this.assignDevice(device,inData) 

    }


//                         <th>Device/System</th>

    

  render() {
    return (
      <>
        <div className="content">
          <Row>

            <Col lg="12" md="12">
              <Card>
                <CardHeader>
                  <CardTitle tag="h2">Devices</CardTitle>
                </CardHeader>
                <CardBody>


		</CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default Devices;

