import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";

import { sbaserver } from '../helpers/SBAServer'


// reactstrap components
import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  DropdownToggle,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Label,
  FormGroup,
  InputGroup,
  Table,
  Row,
  Col,
  UncontrolledTooltip,
  InputGroupText
} from "reactstrap";

// core components
/*
import {
  chartExample1,
  chartExample2,
  chartExample3,
  chartExample4
} from "variables/charts.jsx";
*/

class Reports extends React.Component {

  constructor(props) {
    super(props);
    let thisDate = new Date()
    thisDate.setDate(thisDate.getDate() - thisDate.getDay())
    this.state = { reportBody: <></> , contentList: {}, startDate: thisDate, tempDate: thisDate.clone().toLocaleDateString()}
  }  

  componentDidMount() {
    //this.handleReport.bind(this)()
    sbaserver.emit('queryContent')
  }

    componentWillMount() {
        sbaserver.on('reportData', this.handleReport.bind(this))
        sbaserver.on('content', this.handleContentListChange.bind(this))
	  }


    componentWillUnmount() {
        sbaserver.removeListener('reportData',this.handleReport)
        sbaserver.removeListener('content',this.handleContentListChange)
    }

    handleContentListChange(contentData) {
      let contentList = {}
      for (let content in contentData) {
          //contentList[contentData[content].url] = contentData[content] // sort by URL
          contentList[contentData[content].path] = contentData[content] // sort by Room
      } 
      this.setState({ contentList })
  }

    queryReport(reportData) {
        console.log("Sent queryReport: ", reportData)
        this.setState({reportBody: <h3>Loading Report...</h3>})
        sbaserver.emit('queryReport', reportData)
    }

    handleReport(reportData) {
      console.log('Got report Data: ', reportData)
      let reportBody = <></>
      let seen = {}
      let totals = 0
      if (reportData.request.reportType === "attendanceAllPaths") {
        reportBody = 
        <div><h4>All Content</h4>
        <table style={{color: "white", maxWidth: "600px", width: "100%"}}>
          
        { reportData.results.map(item => (<tr><td>{item.name}: {item.count} Devices, {item.viewership} Viewers</td></tr>))}  
        <tr></tr>
        </table>
        </div>
      } else if (reportData.request.reportType === "pathAttendance") {
        // In the future, load all content and match content names with paths for pretty display of content name (and selection down below)
        reportBody = 
        <div><h4>Report for {this.state.contentList[reportData.request.path].name} </h4>
        <h5>Viewership for { new Date(reportData.request.reportRangeStart).toLocaleTimeString([], { hour: 'numeric', minute: '2-digit', second: undefined}) } - { new Date(reportData.request.reportRangeEnd).toLocaleTimeString([], { hour: 'numeric', minute: '2-digit', second: undefined})} on {new Date(reportData.request.reportRangeStart).toLocaleDateString()}</h5>
        <table style={{color: "white"}}>
          <tr><th>Name</th><th># Viewers</th></tr>
          { reportData.results.map(viewer => { 
            if (viewer.name && !seen[viewer.name]) {
              seen[viewer.name] = true
              totals += viewer.attendance2
              return  <tr><td>{viewer.name}</td><td style={{textAlign: "right"}}>{viewer.attendance2}</td></tr>
            } else {
              return ""
            }
          })}
          <tr><td style={{fontWeight: "bold"}}>Total:</td><td style={{fontWeight: "bold", textAlign: "right"}}>{totals}</td></tr>
        </table>
        </div>
      }

      this.setState({ reportBody }) 

    }


//                         <th>Device/System</th>

    updateTempDate(event) {
      console.log(event.target.value)
      this.setState({tempDate: event.target.value})
    }

    updateDate() {
      this.setState({startDate: new Date(this.state.tempDate)})
    }

  render() {

    let startDate = { nine: {}, tenthirty: {}, twelve: {}, onethirty: {}, four: {}, six: {}, seven: {}}
    let endDate = { nine: {}, tenthirty: {}, twelve: {}, onethirty: {}, four: {}, six: {}, seven: {}}

    for (let j in startDate) {
      if (this.state.startDate)  {
        startDate[j] = this.state.startDate.clone()
        endDate[j] = this.state.startDate.clone()
      } else {
        startDate[j] = new Date()
        endDate[j] = new Date()
        startDate[j].setDate(startDate[j].getDate() - startDate[j].getDay())
        endDate[j].setDate(endDate[j].getDate() - endDate[j].getDay())
      } 
      startDate[j].setMinutes(0)
      startDate[j].setSeconds(0)
      endDate[j].setMinutes(45)
      endDate[j].setSeconds(59)
      if ( j === "nine") { 
        startDate[j].setHours(9)
        endDate[j].setHours(9)
      } else if (j === "tenthirty") {
        startDate[j].setHours(10)
        startDate[j].setMinutes(30)
        endDate[j].setHours(11)
        endDate[j].setMinutes(15)
      } else if (j === "twelve") {
        startDate[j].setHours(12)
        endDate[j].setHours(12)
      } else if ( j === "onethirty") {
        startDate[j].setHours(13)
        startDate[j].setMinutes(30)
        endDate[j].setHours(14)
        endDate[j].setMinutes(15)
      } else if (j === "four") {
        startDate[j].setHours(16)
        endDate[j].setHours(16)
      } else if (j === "six") {
        startDate[j].setHours(18)
        endDate[j].setHours(18)
      } else if (j === "seven") {
        startDate[j].setHours(19)
        endDate[j].setHours(19)
      }
    }
   

    return (
      <>
        <div className="content">
          <Row>

            <Col lg="12" md="12">
              <Card>
                <CardHeader>
                  <CardTitle tag="h2">Reports</CardTitle>
                </CardHeader>
                <CardBody>

                <input type="text" value={this.state.tempDate} onChange={this.updateTempDate.bind(this)}></input>
                <Button onClick={() => this.updateDate() }>Update Date</Button>

                <Button onClick={() => this.queryReport({ reportType: "attendanceAllPaths", reportRangeStart: startDate["nine"].getTime(), reportRangeEnd: endDate["twelve"].getTime()})}>All Attendance for Recent Sunday</Button> <br />

                <br />
                <UncontrolledDropdown group>
                  <DropdownToggle caret size="sm" color="info" data-toggle="content">{ this.state.contentName || "Select Content"}</DropdownToggle>
                  <DropdownMenu>

                  { Object.keys(this.state.contentList).map(path => {
                    if (this.state.contentList[path]) {
                      return <DropdownItem onClick={() => this.setState({path: path, contentName: this.state.contentList[path].name })}>{this.state.contentList[path].name}</DropdownItem>
                    }
                  })}

                  </DropdownMenu>
                </UncontrolledDropdown>
                <br />          
                  <Button onClick={() => this.queryReport({ reportType: "pathAttendance", reportRangeStart: startDate["nine"].getTime(), reportRangeEnd: endDate["nine"].getTime(), path: this.state.path })}>9:00 A</Button>
                  <Button onClick={() => this.queryReport({ reportType: "pathAttendance", reportRangeStart: startDate["tenthirty"].getTime(), reportRangeEnd: endDate["tenthirty"].getTime(), path: this.state.path })}>10:30 A</Button>
                  <Button onClick={() => this.queryReport({ reportType: "pathAttendance", reportRangeStart: startDate["twelve"].getTime(), reportRangeEnd: endDate["twelve"].getTime(), path: this.state.path })}>12:00 A</Button>
                  <Button onClick={() => this.queryReport({ reportType: "pathAttendance", reportRangeStart: startDate["onethirty"].getTime(), reportRangeEnd: endDate["onethirty"].getTime(), path: this.state.path })}>1:30 P</Button>
                  <Button onClick={() => this.queryReport({ reportType: "pathAttendance", reportRangeStart: startDate["four"].getTime(), reportRangeEnd: endDate["four"].getTime(), path: this.state.path })}>4:00 P</Button>
                  <Button onClick={() => this.queryReport({ reportType: "pathAttendance", reportRangeStart: startDate["six"].getTime(), reportRangeEnd: endDate["six"].getTime(), path: this.state.path })}>6:00 P</Button>
                  <Button onClick={() => this.queryReport({ reportType: "pathAttendance", reportRangeStart: startDate["seven"].getTime(), reportRangeEnd: endDate["seven"].getTime(), path: this.state.path })}>7:00 P</Button>
                  <br />
                { this.state.reportBody }

		            </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default Reports;

Date.prototype.clone = function() { return new Date(this.getTime()); };