import React from "react";

// reactstrap components

import {Row, Col} from "reactstrap"

export default class DashboardItem extends React.PureComponent {

	shouldComponentUpdate() {
		return false;
	}

    render() {

	//console.log("Device: ", this.props.device);

	let device = this.props.device
	let statusData = this.props.device.statusData || { 
		indicated: 0, 
		bitrate: 0, 
		bitrateMax: 0, 
		bitrateCalc: 0, 
		avgVidRate: 0, 
		avgAudRate: 0, 
		playbackGood: "",
		playbackEmpty: "",
		playPause: "",
		lastError: "",
		playerStatus: "",
		lastNotification: ""
	}
	
        let indicated = parseInt(statusData.indicated/1000) || 0
        let bitrate = parseInt(statusData.bitrate/1000000) || 0
        let bitrateMax = parseInt(statusData.bitrateMax/1000000) || 0
        let bitrateCalc = parseInt(statusData.bitrateCalc/1000) || 0
        let avgVidRate = parseInt(statusData.avgVidRate/1000) || 0
        let avgAudRate = parseInt(statusData.avgAudRate/1000) || 0
        let keepingUp = statusData.playbackGood
        let bufferEmpty = statusData.playbackEmpty
        let playPause = statusData.playPause
        let lastError = statusData.lastError || ""
        let playerStatus = statusData.playerStatus || ""
        let lastNotification = statusData.lastNotification || ""

	
		return (

                      <tr>

			<td>
				      {!!device && !!device.type && !!device.system ? 
					  device.type.match(/tv/i) ? <i className="tim-icons icon-tv-2" />  :
					  device.system.match(/ios|android/i) ? <i className="tim-icons icon-mobile" />  :
				         device.system.match(/mac|windows/i) ? <i className="tim-icons icon-laptop" />  : "" 
				      : "" }
				    </td>
				    <td>{device.name}<br /><small>{device.attendance ? device.attendance + " viewers" : "1+ viewers"}</small></td>

				    <td className="text-info"><span className="text-info">{Math.trunc(!!statusData.size ? statusData.size.width : 0)} x {Math.trunc(!!statusData.size ? statusData.size.height : 0)}</span></td>
		    <td className="d-none d-md-table-cell">
		    { indicated || avgVidRate || avgAudRate ? < >
		      <i className="tim-icons icon-video-66" />&nbsp;<span className={avgVidRate + avgAudRate <= indicated && avgVidRate > 128 ? "text-success" : "text-warning"}>{avgVidRate}K</span> <br />
		      <i className="tim-icons icon-volume-98" />&nbsp;<span className={avgAudRate + avgVidRate <= indicated && avgAudRate > 24 ? "text-success" : "text-warning"}>{avgAudRate}K</span> <br />
			<i className="tim-icons icon-wifi" />&nbsp;<span className="text-info">{indicated}K</span>
			</>
			: <span className="text-warning">N/A</span> }
		    </td>
		    <td  className="d-none d-md-table-cell">
		    { bitrate || bitrateMax ? <>
			Avg: <span className="text-info">{bitrate} M</span><br />Max: <span className="text-primary">{bitrateMax} M</span>
			</>
			: <span className="text-warning">N/A</span> }
		    </td>
		    
		    <td>
		    <span className={playPause === "playing" ? "text-success" : "text-danger"}><i className={playPause === "playing" ? "tim-icons icon-triangle-right-17" : "tim-icons icon-button-pause"} /></span> &nbsp; 
 		    {playerStatus === "Ready to Play" ? <span  className="d-none d-md-inline text-info">Ready</span> : <span className="text-warning">{playerStatus}</span>}
		    </td>
<td className="d-none d-md-table-cell"><span className="text-info">{this.formatVODTime(statusData.duration)}</span></td>
<td style={{"minWidth": "85px"}}><span className="text-success">{statusData.live ? this.formatTimeWithInterval(statusData.remaining) : this.formatVODTime(statusData.elapsed)}</span></td>
                      </tr> 

		)
    }


    formatVODTime(seconds) {
	// We're getting seconds and formatting it into HH:MM:SS
	let hours = Math.trunc((seconds/3600) % 60);
	let min = Math.trunc((seconds/60) % 60);
	let secs = parseInt(seconds % 60);
	let timeOut = hours + ":" + (min < 10 ? "0"+min : min) + ":" + (secs < 10 ? "0"+secs : secs);
	if (hours < 1) {
	    timeOut = min + ":" + (secs < 10 ? "0"+secs : secs);
	} 
	return timeOut;
    }

    formatTimeWithInterval(interval) {
        let timePositionLive = new Date(Date.now() - interval * 1000)
	  return timePositionLive.timeNow()
    }


    
}


