
import io from 'socket.io-client'
const port = 8080

class SBAServer  {

    constructor() {
        // production
        this.socket = io('/grantsville').connect("https://manage.simplebroadcast.app")
        // Dev mode
        //this.socket = io.connect("//:8080")

        this.socket.on('connect', () => {
            // Normally we would authenticate here
            // auth
            // Join management room
            this.socket.emit('room', {room: 'management'})
    })
    }

    id() {
        console.log("Socket.id: ",this.socket.id)
        return this.socket.id
    }
    // Standard socket.io handlers to pass through

    emit(event, data) {
        this.socket.emit(event, data)
    }

    on(event, cb) {
        //console.log("App registered for a cb on event " + event)
        this.socket.on(event, cb)
    }

    off(event) {
        this.socket.off(event)
    }

    removeListener(event, listener) {
        this.socket.removeListener(event, listener)
    }

    // Custom SBAServer commands 

    // Query status, content & device lists and details
    query(query) {
        // The caller will pass in an object for "query", which will include a 'queryResponse'
        // key and value; the value will be the response the server will use to send it back
        this.socket.emit('query',query)
    }

    command(command) {
        // They'll do the same for a command
        this.socket.emit('command',command)
    }

    crud(data) {
        // Send an add, update, remove command in the object
        // includes { action: <add|update|remove>, list: }
        this.socket.emit('crud',data)
    }

}

export let sbaserver = new SBAServer()

